import React, { useEffect, useState } from "react";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import { Picker } from '../common/inputs';
import { categoryTypes } from "../../config/predefinedValues.json";

import entityService from "../../services/entityService";
import useApi from '../../hooks/useApi';

function GuarantorsList({ onSelectItem, selectedGuarantors }) {
    const defaultVal = { label: "Start typing to search for guarantor", value: null };
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);  // Changed to array for multiSelect
    const additionalParams = {
        country_id: "",
        category_type: "3",
        category_id: categoryTypes["Guarantor"],
        entityDropDown: true,
    };

    const { request, loading } = useApi(entityService.fetchKeywordSuggestions);

    const searchGuarantor = async (searchKey) => {
        const res = await request(searchKey, additionalParams);
        setSearchResults(res.list);
    };

    useEffect(() => {
        if (selectedGuarantors && selectedGuarantors.length > 0) {
            // Ensure selectedGuarantor is an array of objects for multiSelect
            setSelectedItems(selectedGuarantors);
        } else {
            setSelectedItems([]);  // Reset when no guarantor is passed
        }
    }, [selectedGuarantors]);

    const populateList = async () => {
        const res = await request("", additionalParams);
        setSearchResults(res.list);
    };

    useEffect(() => {
        populateList();
    }, []);

    return (
        <>
            {loading && <LoadingSpinner />}
            <Picker
                items={searchResults}
                selectedItem={selectedGuarantors} 
                onSelectItem={(selectedOptions) => { 
                    setSelectedItems(selectedOptions); 
                    onSelectItem(selectedOptions);
                }}
                onKeyDown={searchKey => searchGuarantor(searchKey)}
                multiSelect={true}
            />
        </>
    );
};

export default GuarantorsList;
