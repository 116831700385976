import React, { useState, useRef, useEffect } from "react";
import Table from "../common/tables/ActionTable";
import { BreadCrumbs } from "../common/layout";
import { Helmet } from "react-helmet";
import entityService from "../../services/entityService";

import { CountriesList, BPList } from "../slplusTools";
import { Picker, ViewButton } from "../common/inputs";
import { toast } from "react-toastify";
import { Link } from "react-router";
import Select from "react-select";
import authService from "../../services/authService";
import "../../styles/react-table.css";
import "react-table/react-table.css";

// CustomSearch component
const CustomSearch = ({
  searchKeyword,
  handleCustomSearch,
  handleKeyPress,
  clearSearch,
}) => {
  console.log(searchKeyword);
  return (
    <div className="row mt-2">
      <div className="col-lg-3">
        <div className="input-group mb-3">
          <input
            type="text"
            value={searchKeyword}
            className="form-control"
            placeholder="Search using name, email and phone"
            onChange={handleCustomSearch}
            onKeyDown={handleKeyPress}
          />
          <div className="input-group-append">
            <button
              onClick={clearSearch}
              className="btn btn-outline-secondary"
              type="button"
            >
              <i
                className={`fa fa-${
                  searchKeyword.length > 0 ? "close" : "search"
                }`}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function AllMembers() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const tableRef = useRef();
  const { constants, category } = authService.getCurrentUser();

  const [country, setCountry] = useState(null);
  const [bp, setBp] = useState(null);
  const [accountType, setAccountType] = useState("all");
  const [businessType, setBusinessType] = useState("all");
  const [showExport, setShowExport] = useState(false);
  const [tableParams, setTableParams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);

  const [keyword, setKeyword] = useState("");

  const handleBpChange = async (optionVal) => {
    await setBp(optionVal === null ? "all" : optionVal);
    tableRef.current.reloadData();
  };
  const handleBusinessTypeChange = async (optionVal) => {
    await setBusinessType(optionVal);
    tableRef.current.reloadData();
  };
  //---------------------------------------------------------------------------------------
  const handleCustomSearch = (e) => {
    const keyWord = e.target.value;
    setSearchKeyword(keyWord.replace(/^ /, ""));
    /*  if (keyWord.length > 2) {
      tableRef.current.reloadData();
    }*/
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      (e.target.value.length > 0) & (e.target.value.length < 3) &&
        toast.error("Enter atleast 3 charectors");
    }
  };
  const clearSearch = () => {
    searchKeyword.length > 0
      ? setSearchKeyword("")
      : tableRef.current.reloadData();
  };

  //-------------------------------------------------------------------------------------------
  const businessTypes = [
    { label: "All", value: "all" },
    { label: "Corporate", value: "Corporate" },
    { label: "Network", value: "Network" },
  ];
  const columns = [
    {
      Header: "Organization",
      accessor: "organization",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <>
          <span title={row.original.error}>{row.original.organization}</span>
          <span className="badge badge-light ml-2">
            {row.original.category_name}
          </span>
        </>
      ),
    },
    {
      Header: "First Name",
      accessor: "first_name",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <span title={row.original.error}>{row.original.first_name}</span>
      ),
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <span title={row.original.error}>{row.original.last_name}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <span title={row.original.error}>{row.original.email}</span>
      ),
    },
    {
      Header: "Phone No",
      accessor: "phone_no",
      headerStyle: { textAlign: "left" },
      Cell: (row) => (
        <span title={row.original.error}>{row.original.phone_no}</span>
      ),
    },
    {
      Header: "User Access",
      accessor: "user_access",
      headerStyle: { textAlign: "left" },
      minWidth: 50,
      Cell: (row) => (
        <span
          title={row.original.error}
          className={`text-${row.original.uid ? "primary" : "danger"}`}
        >
          {row.original.uid ? "Yes" : "No"}
        </span>
      ),
    },
    {
      Header: "MFA",
      accessor: "mfa",
      headerStyle: { textAlign: "left" },
      minWidth: 50,
      Cell: (row) => <>{row.original.mfa && row.original.mfa}</>,
    },
    {
      Header: "Action",
      accessor: "action",
      headerStyle: { textAlign: "left" },
      minWidth: 50,
      Cell: (row) => (
        <Link
          className="action-icons"
          to={{
            pathname: `/entities/members/profile`,
            search: "?contact_id=" + row.original.contact_id,
          }}
        >
          <ViewButton />
        </Link>
      ),
    },
  ];
  const FilterOptions = () => {
    const customStyles = {
      menu: (provided) => ({ ...provided, zIndex: 999 }),
    };

    return <></>;
  };
  useEffect(() => {
    (searchKeyword.length > 2 || searchKeyword.length == 0) &&
      tableRef.current.reloadData();
  }, [searchKeyword]);

  return (
    <>
      <Helmet>
        <title>All Team Members | {global.config.title}</title>
      </Helmet>
      <BreadCrumbs
        Header="All Team Members"
        activeModule="All Team Members"
        Route1="/dashboard"
        Label1="Dashboard"
        Route2="/entities"
        Label2="Accounts"
      />
      <div className="card overflow-visible">
        <div className="card-header">
          <h6>
            All Contacts
            <Link
              className="btn  btn-light  rounded-full mr-2 btn-closeHeader pull-right"
              title="close and go back to listing"
              to="/entities"
            >
              <i alt="close this page" class="fa fa-times mr-1"></i>
            </Link>
          </h6>
        </div>
        <div className="card-body">
          <div className="">
            <div className="row">
              {category == constants.CATEGORY.INTL_OFFICE && (
                <div className="col-lg-3 ">
                  <CountriesList
                    type="vertical"
                    selectedCountry={country}
                    onSelectItem={({ id }) => setCountry(id)}
                  />
                </div>
              )}

              {(category == constants.CATEGORY.INTL_OFFICE ||
                category == constants.CATEGORY.COUNTRY_OFFICE) && (
                <>
                  <div className="col-lg-3">
                    <BPList
                      selectedBp={bp}
                      type="vertical"
                      country={country}
                      onSelectItem={({ value }) => handleBpChange(value)}
                    />
                  </div>

                  <div className="col-lg-3 ">
                    <Picker
                      items={businessTypes}
                      selectedItem={businessTypes.find(
                        (item) => item.value === businessType
                      )}
                      onSelectItem={({ value }) =>
                        handleBusinessTypeChange(value)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <Table
            ref={tableRef}
            service={entityService.listAllMembers}
            customSearch={
              <CustomSearch
                searchKeyword={searchKeyword}
                handleCustomSearch={handleCustomSearch}
                handleKeyPress={handleKeyPress}
                clearSearch={clearSearch}
              />
            }
            cols={columns}
            tableLeftPanel={<FilterOptions />}
            reqParams={{
              searchKeyword,
              bp,
              accountType,
              businessType,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default AllMembers;
