import React from "react";
import ReactTable from "react-table";
import Form from "../../common/Form";
import Select from "react-select";
import { transStatus } from "../../../config/predefinedValues.json";
import withFixedColumns from "react-table-hoc-fixed-columns";
import httpRequest from "../../../services/httpService";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class ActionTable extends Form {
  state = {
    data: [],
    pages: 1,
    loading: false,
    tabs: [],
    selectedTab: null,
    columns: [],
    checkAll: false,
    noDataText: "",
    showRight: false,
    filter: {
      page: 1,
      pageSize: 10,
      sorted: [],
      filtered: [],
    },
    selectedRows: [],
    selectedSearchTerm: null,
    reqParams: this.props.reqParams,
    pivot: [],
    defaultExpandedRows: false,
    additionalFiltersModal: false,
    additionalFilters: {},
    additionalFiltersTemp: {},
    showApplyFilterBtn: false,
  };
  componentDidUpdate(prevProps) {
    // To switch default tab

    if (this.props.defaultTab != prevProps.defaultTab) {
      this.setState({ selectedTab: this.props.defaultTab }, () => {
        this.setState({ selectedRows: [] });
        this.reloadData();
        if (
          this.props.pivot &&
          (this.props.defaultTab == transStatus[0]["DRAFT"] ||
            this.props.defaultTab == transStatus[0]["APPROVED"] ||
            this.props.defaultTab == transStatus[0]["REJECTED"]) &&
          this.props.source == "single"
        ) {
          this.setState({ pivot: [] });
        } else if (
          (this.props.defaultTab && this.props.source == "batch") ||
          (this.props.defaultTab != transStatus[0]["DRAFT"] &&
            this.props.source == "single")
        ) {
          this.setState({ pivot: [this.props.pivot] });
        }
      });
    }

    if (this.props.reqParams != this.state.reqParams) {
      this.setState({ reqParams: this.props.reqParams });
      //this.reloadData()
    }
    if (this.props.cols != this.state.columns) {
      let column = {};
      if (this.props.onSelectRow) {
        column = this.props.cols;
        if (!this.props.hideCheckbox) {
          column = this.handleCheckboxMount(column);
        }
      } else column = this.props.cols;
      this.setState({ columns: column });
    }
  }
  componentDidMount() {
    if (this.props.searchService) this.populateSearchList();

    if (this.props.onSelectRow) {
      let columns = [...this.props.cols];

      if (!this.props.hideCheckbox) {
        columns = this.handleCheckboxMount(columns);
      }
      this.setState({ columns });
    } else this.setState({ columns: this.props.cols });

    if (
      this.props.pivot &&
      this.props.defaultTab == transStatus[0]["DRAFT"] &&
      this.props.source == "single"
    ) {
      this.setState({ pivot: [] });
    } else if (
      (this.props.defaultTab == transStatus[0]["DRAFT"] &&
        this.props.source == "batch") ||
      (this.props.defaultTab != transStatus[0]["DRAFT"] &&
        this.props.source == "single")
    ) {
      this.setState({ pivot: [this.props.pivot] });
    }
  }
  handleCheckboxMount = (columns) => {
    columns.unshift({
      accessor: "rowIndex",
      minWidth: 40,
      headerStyle: { textAlign: "left" },
      sortable: false,
      fixed: "left",
      getProps: (state, rowInfo, column) => {
        if (this.props.setBackgroundcolor) {
          return {
            style: {
              background:
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.trans_approved_status == this.props.dangerRow
                  ? "rgba(250, 92, 124,0.2)"
                  : null,
            },
          };
        } else {
          return {};
        }
      },

      Header: () => {
        return (
          <div className="custom-control d-flex custom-checkbox small header-checkbox">
            <input
              id="customCheck"
              type="checkbox"
              checked={this.state.checkAll}
              className="custom-control-input"
              disabled={
                this.state.data.filter((obj) => obj.disabled == 1).length ===
                this.state.data.length
                  ? true
                  : false
              }
              onClick={(e) => {
                const selectedRows = [...this.state.selectedRows];
                this.state.data.map((item) => {
                  if (selectedRows.length > 0) {
                    var rowIndex = selectedRows.findIndex(
                      (obj) => obj.key == item[this.props.uid]
                    );
                    if (rowIndex != -1) {
                      //     selectedRows[rowIndex]["checked"] = !selectedRows[
                      //     rowIndex
                      //   ]["checked"];
                      if (e.target.checked == true)
                        selectedRows[rowIndex]["checked"] = true;
                      else selectedRows[rowIndex]["checked"] = false;
                    } else
                      selectedRows.push({
                        key: item[this.props.uid],
                        checked: e.target.checked,
                        ...item,
                      });
                  } else
                    selectedRows.push({
                      key: item[this.props.uid],
                      checked: e.target.checked,
                      ...item,
                    });
                });
                this.setState({ selectedRows, checkAll: !this.state.checkAll });
                this.props.onSelectRow && this.props.onSelectRow(selectedRows);
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="customCheck"
            ></label>
          </div>
        );
      },

      Cell: (row) => {
        let checked = false;
        let rowIndex = this.state.selectedRows.findIndex(
          (obj) => obj.key == row.original[this.props.uid]
        );

        if (rowIndex != -1)
          checked = this.state.selectedRows[rowIndex]["checked"];

        return !row.original.disabled ||
          (row.original.disabled && row.original.disabled === "0") ? (
          <div className="custom-control d-flex custom-checkbox small">
            <input
              id={row.original[this.props.uid]}
              type="checkbox"
              checked={checked}
              className="custom-control-input"
              onClick={(e) => this.handleCheckboxChange(e, row)}
            />
            <label
              className="custom-control-label"
              htmlFor={row.original[this.props.uid]}
            ></label>
          </div>
        ) : (
          ""
        );
      },
    });
    return columns;
  };
  //---------------Handle checkbox change-----------------//
  handleCheckboxChange = (e, row) => {
    const selectedRows = [...this.state.selectedRows];
    var rowIndex = selectedRows.findIndex(
      (obj) => obj.key == row.original[this.props.uid]
    );
    if (rowIndex != -1) selectedRows[rowIndex]["checked"] = e.target.checked;
    else
      selectedRows.push({
        key: row.original[this.props.uid],
        checked: e.target.checked,
        ...row.original,
      });

    this.setState({ selectedRows });
    this.props.onSelectRow && this.props.onSelectRow(selectedRows);
  };

  //---------------Method to Fetch data to the Table-----------------//
  fetchData = async (params) => {
    httpRequest.cancel();
    if (this.props.service) {
      this.setState({ data: [], loading: true, noDataText: "" });

      await this.props
        .service(params, this.state.selectedTab)
        .then((res) => {
          const tabs = res.data.tabs; //.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
          this.setState({
            data: res.data.rows,
            tabs: tabs,
            pages: res.data.pages,
            loading: false,
          });

          if (this.props.onDataLoad) this.props.onDataLoad(res.data, params);

          if (this.props.headerSelect) {
            const result = res.data.tabs.find(
              (item) => item.value == this.state.selectedTab
            );
            this.setState({ tabOption: result });
          }

          if (res.data?.rows.length === 0)
            this.setState({ noDataText: "No data found." });
        })
        .catch((error) => console.log(error));
    }

    if (this.props.pivot) {
      // Set all the rows index to true
      let expandedData = this.state.data.map((element, index) => {
        return { index: true };
      });
      this.setState({ defaultExpandedRows: expandedData });
    }
  };
  //----------------------------------------------------------------//

  //---------------Method to Fetch data to the Table-----------------//
  populateSearchList = async (searchTerm) => {
    this.setState({
      //loading: true,
      searchTerm: { label: "Search", value: "" },
    });
    await this.props
      .searchService({
        searchTerm,
        selectedTab: this.state.selectedTab
          ? this.state.selectedTab
          : this.props.defaultTab
          ? this.props.defaultTab
          : null,
        ...this.props.reqParams,
        ...this.state.additionalFilters,
      })
      .then((res) => this.setState({ searchList: res.data }))
      .catch((error) => console.log(error));
    //this.setState({ loading: false });
  };
  //----------------------------------------------------------------//

  searchKeyChange = async (option) => {
    if (option) {
      const filterData = { ...this.state.filter, ...this.props.reqParams };
      filterData["search"] = option.value;
      this.fetchData(filterData);

      this.setState({
        selectedSearchTerm: { label: option.label, value: option.value },
      });
    } else this.reloadData();
  };

  //---------------Handle search key down-----------------//
  searchKeyDown = async (e) => {
    if (e.keyCode == 46 || e.keyCode == 8) {
      const filterData = { ...this.state.filter };
      filterData["search"] = null;
      this.fetchData(filterData);
      this.setState({ selectedSearchTerm: null });
    } //else
    //await this.populateSearchList(e.target.value);
  };

  //--------This method can be called from the Parent Component-----//
  reloadData = () => {
    this.setState({ selectedSearchTerm: null });
    const filterData = {
      ...this.state.filter,
      ...this.props.reqParams,
      ...this.state.additionalFilters,
    };
    //filterData["search"] = this.state.selectedSearchTerm.value;
    this.fetchData(filterData); // Use this method If you want to reload the data forcefully from the parent component.

    if (this.props.searchService) this.populateSearchList();

    this.setState({ checkAll: false });
  };
  //----------------------------------------------------------------//

  //--------This method can be called from the Parent Component-----//
  handleResetFilter = () => {};
  /*
  componentDidUpdate(prevProps) {
    if (this.props.selectedFilter !== prevProps.selectedFilter) {
      const { page, pageSize, sorted, filtered } = this.state;
      this.fetchData({
        page,
        pageSize,
        sorted,
        filtered,
        ...this.props.reqParams,
      })
    }
  }*/
  handleUpdateFilter = (additionalFiltersTemp) => {
    this.setState({ additionalFiltersTemp, showApplyFilterBtn: true });
  };

  getTableAdditionalFilters = () => {
    return this.state.additionalFilters;
  };
  //----------------------------------------------------------------//

  //--------This method can be called from the Parent Component-----//
  getTableData = () => {
    return this.state.data;
  };
  //----------------------------------------------------------------//

  //--------This method can be called from the Parent Component-----//
  clearSelection = () => {
    this.setState({ selectedRows: [] });
  };
  //----------------------------------------------------------------//

  render() {
    const customStyles = {
      menu: (provided) => ({ ...provided, zIndex: 999 }),
    };
    const { defaultExpandedRows } = this.state;
    return (
      <>
        {/*--------------------Table Title Row Start-----------------------------*/}
        {this.props.titleRow && (
          <div className="row align-items-center mx-0 px-4 pt-4">
            <div>
              <h4 className="card-title">Title</h4>
            </div>
            <div className="ml-auto ">
              <button className="btn  btn-light">
                <i className="fa fa-cogs"></i>
              </button>
              <button className="btn btn-light mx-2 ">
                <i className="fa fa-archive"></i>
              </button>
              <button className="btn btn-light ">
                <i className="fa fa-sitemap"></i>
              </button>
            </div>
          </div>
        )}
        {/*-------------------------Table Title Row End-------------------------------*/}

        {/*------------------------------Tabs List Starts-------------------------------*/}
        {this.state.tabs?.length > 0 && this.props.headerTabs && (
          <div className="tabs-container" id="tab_holder">
            <ul className="nav nav-tabs" role="tablist">
              {!this.props.headerSelect &&
                this.state.tabs.map((obj) => (
                  <li
                    className={`${
                      this.state.selectedTab === obj.value &&
                      "active border-bottom"
                    } ${
                      obj.showRight === true && "nav-item ml-auto"
                    }  btn_link_${obj.value}`}
                  >
                    <a
                      className="nav-link  rounded-xl "
                      onClick={() =>
                        this.setState({ selectedTab: obj.value }, () => {
                          // to unselect rows while switching tabs //
                          this.setState({ selectedRows: [] });

                          // To handle pivot setting in tabs other than draft tabs on switching tabs  - single invoice //
                          if (
                            obj.value == transStatus[0]["DRAFT"] &&
                            this.props.source == "single"
                          ) {
                            this.setState({ pivot: [] });
                          } else if (
                            this.props.defaultTab == transStatus[0]["DRAFT"] &&
                            this.props.source == "batch"
                          ) {
                            this.setState({ pivot: [this.props.pivot] });
                          }

                          if (obj.showRight === true) {
                            this.props.onSelectTab &&
                              this.props.onSelectTab(obj.value);
                            this.setState({ showRight: true });
                          } else {
                            if (!this.props.disableAutoReLoadOnTabChange) {
                              // Do not refresh the list if this props enabled.
                              this.reloadData();
                              if (this.state.searchService) {
                                this.populateSearchList();
                              }
                              this.props.onSelectTab &&
                                this.props.onSelectTab(obj.value);
                            } else {
                              this.props.onSelectTab &&
                                this.props.onSelectTab(obj.value);
                            }
                            this.setState({ showRight: false });
                          }
                        })
                      }
                    >
                      {obj.label &&
                        obj.label.charAt(0).toUpperCase() +
                          obj.label.slice(1)}{" "}
                      &nbsp;&nbsp;
                      {typeof obj.count !== "undefined" &&
                        obj.count !== null && (
                          <span
                            className={`label label-${
                              this.props.tabLabelColor
                                ? this.props.tabLabelColor
                                : "secondary rounded-full"
                            }`}
                          >
                            {obj.count}
                          </span>
                        )}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
        {/*------------------------------Tabs List Ends-------------------------------*/}
        {/*-----------------------------------------------------------------------*/}

        <div className="">
          <div className="row">
            <div className="col-lg-7 col-xl-7 col-md-12 col-sm-12 d-flex justify-content-start">
              {this.props.searchService ? (
                <div className="p-1" style={{ minWidth: "35%" }}>
                  <div className={`tbl-search-box`}>
                    <div className="form-group ">
                      <Select
                        options={this.state.searchList}
                        placeholder="Search here"
                        components={{ DropdownIndicator: () => null }}
                        onChange={this.searchKeyChange}
                        onKeyDown={this.searchKeyDown}
                        styles={customStyles}
                        value={this.state.selectedSearchTerm}
                        isClearable
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: ".375rem",

                          colors: {
                            ...theme.colors,
                            text: "black",
                            primary25: "white",
                            primary: "#1ab394",
                            neutral0: "white",
                          },
                        })}
                        formatOptionLabel={(option) => (
                          <div
                            style={{
                              fontWeight: option.bold ? "bold" : "normal",
                            }}
                          >
                            {option.label}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : this.props.topHeader ? (
                this.props.topHeader
              ) : null}
              {/*------------------------------------------------------------------------*/}

              {/*------------------------------------------------------------*/}

              {this.props.headerSelect ? (
                <div className="p-1" style={{ minWidth: "30%" }}>
                  <div className="  tbl-inv-status-holder">
                    <div className="form-group">
                      <Select
                        options={this.state.tabs}
                        placeholder="Invoice status"
                        //  components={{ DropdownIndicator: () => null }}
                        onChange={(selectedOption) => {
                          this.setState(
                            {
                              selectedTab: selectedOption.value,
                              selectedRows: [],
                              tabOption: {
                                label: selectedOption.label,
                                value: selectedOption.value,
                              },
                            },
                            () => {
                              this.reloadData();
                              if (this.state.searchService) {
                                this.populateSearchList();
                              }
                              this.props.onSelectTab &&
                                this.props.onSelectTab(selectedOption.value);
                            }
                          );
                        }}
                        styles={customStyles}
                        value={this.state.tabOption}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: ".375rem",

                          colors: {
                            ...theme.colors,
                            text: "black",
                            primary25: "white",
                            primary: "#1ab394",
                            neutral0: "white",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {/*--------------------------------------------------------------------*/}

              {this.props.tableLeftPanel && (
                <div className="p-1 " style={{ minWidth: "30%" }}>
                  <div className="  tbl-left-panel-holder">
                    <div>{this.props.tableLeftPanel}</div>
                  </div>
                </div>
              )}

              {this.props.additionalFilters && (
                <div className="p-1  " style={{ minWidth: "5%" }}>
                  <button
                    style={{ minHeight: "40px" }}
                    onClick={() => {
                      this.setState({ additionalFiltersModal: true });
                    }}
                    className="settings_btn btn btn-light "
                  >
                    <i
                      alt="Apply Additional Filters"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Apply Additional Filters"
                      className="fa fa-filter mt-1"
                    />

                    {_.size(_.omitBy(this.state.additionalFilters, _.isNil)) > 0
                      ? ` (${_.size(
                          _.omitBy(this.state.additionalFilters, _.isNil)
                        )})`
                      : ""}
                  </button>
                </div>
              )}
            </div>
            <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 d-flex justify-content-end">
              <div className="form-group tbl-right-panel-holder">
                {this.props.tableRightPanel}
              </div>
            </div>
          </div>
        </div>

        <div>
          {this.state.showRight === false ? (
            <>
              <div className="row ">
                {/*-----------------------------Search List-----------------------------
                <div className={`col-lg-${this.props.tableLeftPanel ? 3 : 7} col-md-4 col-sm-4 z-10`}>


                  <div className={`row ${this.props.topHeader ? 'mb-4' : ''}`}>


    



                  </div>


                </div>



                <div className={`col-lg-${this.props.additionalFilters ? 4 : 5} col-md-${this.props.additionalFilters ? 4 : 5} col-sm-${this.props.additionalFilters ? 4 : 5}`}>

                </div>*/}
                {/*-----------------------------------------------------------------------*/}
              </div>

              {/*-----------Action Panel to show ------------------------------------------*/}
              {this.props.actionPanel && this.props.actionPanel}
              {this.props.customSearch && <div>{this.props.customSearch}</div>}
              {/*==========================REACT TABLE Starts==============================*/}
              <ReactTableFixedColumns
                data={this.state.data}
                minRows={this.state.data.length === 0 ? 3 : 1}
                pages={this.state.pages}
                columns={this.state.columns}
                loading={this.state.loading}
                pageSizeOptions={[5, 10, 20, 25, 50, 100, 250]}
                defaultPageSize={10}
                className={`-striped -highlight ${
                  this.props.dynamicHeight ? "tble-dyn-height" : ""
                }`}
                showPagination={
                  "showPagination" in this.props
                    ? this.props.showPagination
                    : true
                }
                showPaginationTop={false}
                pivotBy={
                  this.props.disablePivot === true ? [] : this.state.pivot
                }
                expanded={defaultExpandedRows}
                onExpandedChange={(newExpanded, index) => {
                  if (newExpanded[index[0]] === true) {
                    Object.keys(newExpanded).map((k) => {
                      newExpanded[k] = {};
                    });
                  }
                  this.setState({
                    ...this.state,
                    defaultExpandedRows: newExpanded,
                  });
                }}
                manual
                noDataText={this.state.noDataText}
                onFetchData={async (state, instance) => {
                  //---------------Saving table default props to the component state----------------------//
                  const { page, pageSize, sorted, filtered } = state;
                  this.setState({
                    filter: {
                      page,
                      pageSize,
                      sorted,
                      filtered,
                      ...this.props.reqParams,
                    },
                  });
                  //--------------------------------------------------------------------------------------//

                  //--------------Set Tabs List----------------//
                  if (this.props.tabs) this.setState({ tabs: this.props.tabs });
                  //-------------------------------------------//

                  //--------------Set Default Tab--------------//
                  if (this.props.defaultTab)
                    this.setState({ selectedTab: this.props.defaultTab }, () =>
                      this.reloadData()
                    );
                  //-------------------------------------------//
                  else
                    this.fetchData({
                      page,
                      pageSize,
                      sorted,
                      filtered,
                      ...this.props.reqParams,
                    });
                }}
                getTrProps={this.props.getTrProps}
              />
              <div style={{ minHeight: "200px" }}></div>
              {/*=======================================================================================*/}
            </>
          ) : (
            this.props.rightPanel
          )}
        </div>

        {/*--------------- Additional Filters Model ---------------*/}
        <Modal
          show={this.state.additionalFiltersModal}
          onHide={(e) => {
            this.setState({ additionalFiltersModal: false });
          }}
          centered
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Apply Filters</h4>
          </Modal.Header>
          <Modal.Body>
            {this.props.additionalFilters ? this.props.additionalFilters : ""}
          </Modal.Body>
          <Modal.Footer>
            <div className="row text-right">
              {_.size(_.omitBy(this.state.additionalFilters, _.isNil)) > 0 && (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                  onClick={() =>
                    this.setState(
                      { additionalFilters: {}, additionalFiltersModal: false },
                      () => {
                        this.reloadData();
                      }
                    )
                  }
                >
                  Reset Filter
                </button>
              )}
              {this.state.showApplyFilterBtn && (
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    this.setState(
                      { additionalFilters: this.state.additionalFiltersTemp },
                      () => {
                        this.reloadData();
                        this.setState({ additionalFiltersModal: false });
                      }
                    );
                  }}
                >
                  Apply Filters
                </button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ActionTable;
