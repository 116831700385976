import { param } from "jquery";
import client from "./serviceClient";

const getUploadCategories = params => client.post(`/entitycustomersecurity/security-document/get-categories`, { ...params });

const getSuretyBondDetailsList = params => client.post(`/entitycustomersecurity/surety-bond-details/index`, { ...params });
const createSuretyBondDetailsList = params => client.post(`/entitycustomersecurity/surety-bond-details/create`, { ...params });
const updateSuretyBondDetailsList = params => client.post(`/entitycustomersecurity/surety-bond-details/update`, { ...params });

const viewSuretyBondDetailsList = params => client.post(`/entitycustomersecurity/surety-bond-details/view`, { ...params });
const deleteSuretyBond= params => client.post(`/entitycustomersecurity/surety-bond-details/delete`, { ...params });
const getTradeCreditInsuranceList = params => client.post(`/entitycustomersecurity/trade-credit-insurance/index`, { ...params });
const createTradeCreditInsuranceList = params => client.post(`/entitycustomersecurity/trade-credit-insurance/create`, { ...params });
const updateTradeCreditInsuranceList = params => client.post(`/entitycustomersecurity/trade-credit-insurance/update`, { ...params });

const viewTradeCreditInsuranceList = params => client.post(`/entitycustomersecurity/trade-credit-insurance/view`, { ...params });
const deleteTradeCreditInsurance = params => client.post(`/entitycustomersecurity/trade-credit-insurance/delete`, { ...params });
const uploadDocument = params => client.post(`/entitycustomersecurity/security-document/upload-document`, { ...params });
const listDocument = params => client.post(`/entitycustomersecurity/security-document/list-document`, { ...params });
const viewDocument = params => client.post(`/entitycustomersecurity/security-document/view-document`, { ...params });
const deleteDocument = params => client.post(`/entitycustomersecurity/security-document/delete-document`, { ...params });

const addPPSRRegistration = params => client.post(`/entitycustomersecurity/ppsr-registrations/create-ppsr`, { ...params });

const listPPSRRegistrations = params => client.post(`/entitycustomersecurity/ppsr-registrations/list-ppsr`, { ...params });

const updatePPSRRegistration = params => client.post(`/entitycustomersecurity/ppsr-registrations/update-ppsr`, { ...params });

const exportExcel = params => client.post(`/entitycustomersecurity/security-document/export`,{ ...params}); 

const createSecurityRisk = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/create`, { ...params });

const updateSecurityRisk = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/update`, { ...params });

const listMitigants = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/list`, { ...params });

const deletePPSRDetails = params => client.post(`/entitycustomersecurity/ppsr-registrations/delete-ppsr`, { ...params });

const deleteGurantorDetails = params => client.post(`/entity/guarantor/delete-guarantor`, { ...params });

const deleteSecurityRiskDetails = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/delete`, { ...params });

const validateExcelSheet = params => client.post(`/entity/guarantor/validate-excel-sheet`, { ...params });

const createGuarantorBulkDetails = params => client.post(`/entity/guarantor/upload-bulk-guarantor`, { ...params });

const getEntityIdByOrganisation = params => client.post(`/entity/guarantor/get-entity-organisation`, { ...params });
const validateSbdExcelSheet = params => client.post(`/entitycustomersecurity/surety-bond-details/validate-excel-sheet`, { ...params });
const processSbdExcelSheet = params => client.post(`/entitycustomersecurity/surety-bond-details/process-sbd-excel`, { ...params });
const suretyBondBulkUpload = params => client.post(`/entitycustomersecurity/surety-bond-details/bulk-upload`, { ...params })

const processTciExcelSheet = params => client.post(`/entitycustomersecurity/trade-credit-insurance/process-tci-excel`, { ...params });
const validateTciExcelSheet = params => client.post(`/entitycustomersecurity/trade-credit-insurance/validate-tci-excel`, { ...params });

const tradeCreditInsuranceBulkUpload = params => client.post(`/entitycustomersecurity/trade-credit-insurance/bulk-upload`, { ...params })

const validatePpsrExcelSheet = params => client.post(`/entitycustomersecurity/ppsr-registrations/validate-excel-sheet`, { ...params });
const uploadBulkPpsr = params => client.post(`/entitycustomersecurity/ppsr-registrations/upload-bulk-ppsr`, { ...params });

const validateOtherSecurityExcelSheet = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/validate`, { ...params });
const uploadBulkOtherSecurity = params => client.post(`/entitycustomersecurity/other-security-risk-mitigants/add-bulk`, { ...params });

export default {
    getTradeCreditInsuranceList,
    createTradeCreditInsuranceList,
    updateTradeCreditInsuranceList,
    viewTradeCreditInsuranceList,
    deleteTradeCreditInsurance,
    getSuretyBondDetailsList,
    createSuretyBondDetailsList,
    updateSuretyBondDetailsList,
    viewSuretyBondDetailsList,
    deleteSuretyBond,
    getUploadCategories,
    uploadDocument,
    listDocument,
    viewDocument,
    deleteDocument,
    addPPSRRegistration,
    listPPSRRegistrations,
    updatePPSRRegistration,
    exportExcel,
    createSecurityRisk,
    updateSecurityRisk,
    listMitigants,
    deletePPSRDetails,
    deleteGurantorDetails,
    deleteSecurityRiskDetails,
    validateExcelSheet,
    createGuarantorBulkDetails,
    getEntityIdByOrganisation,
    validateSbdExcelSheet,
    suretyBondBulkUpload,
    processTciExcelSheet,
    validateTciExcelSheet,
    tradeCreditInsuranceBulkUpload,
    validatePpsrExcelSheet,
    uploadBulkPpsr,
    validateOtherSecurityExcelSheet,
    uploadBulkOtherSecurity,
    processSbdExcelSheet,
};
