import React, { useState, useEffect } from "react";
import { Picker } from "../common/inputs";
import authService from "../../services/authService";

function FeeCategory({ selectedCategory = null, hideTransFeeCategs = false, onSelectItem, defaultValue = { label: "Select fee category", value: null } }) {
  const { constants } = authService.getCurrentUser();

  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [feeCategory, setFeeCategory] = useState([]);

  useEffect(() => {
    const categories = constants?.GENERAL_FACILITY_FEE_CATEGORIES || {};
    const feeCategoryList = Object.keys(categories).map((key) => ({
      label: key,
      value: categories[key],
    }));

    setFeeCategory(hideTransFeeCategs ? feeCategoryList.filter(item => item.value !== constants.GENERAL_FACILITY_FEE_CATEGORIES['Transaction Fee'] && item.value !== constants.GENERAL_FACILITY_FEE_CATEGORIES['Daily Rate']) : feeCategoryList);

    const selectedFeeCategory = feeCategoryList.find((item) => item.value === selectedCategory);
    setSelectedItem(selectedFeeCategory || defaultValue);
  }, [selectedCategory]);
  return (
    <>
      {selectedItem && (
        <Picker
          items={feeCategory}
          selectedItem={selectedItem}
          onSelectItem={(selectedOption) => {
            setSelectedItem(selectedOption);
            onSelectItem(selectedOption);
          }}
        />
      )}
    </>
  );
}

export default FeeCategory;
