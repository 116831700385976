import React, { useState, useContext } from "react";
import Joi from "joi-browser";
import ValidateSheetFields from "../utils/validateSheetFields";
import { confirmAlert } from "react-confirm-alert";
import securityServices from "../services/securityServices";


// Create a new context for the Supplier Account
const UploaderContext = React.createContext();

export function useUploader() {
    return useContext(UploaderContext);
}  

export function GuarantorUploaderProvider({ children,location }) {
    
    const [guarantors, setGuarantors] = useState([]);
    const [uniqueGuarantors, setUniqueGuarantors] = useState([]);
    const [duplicateAccountsInSheet, setDuplicateAccountsInSheet] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [proceedNext, setProceedNext] = useState(true);
    const [uploadedFileData, setUploadedFileData] = useState([]);
    const [uploading, setUploading] = useState(0);

    const [hideNextBtn, sethideNextBtn] = useState(false);
    const [hidePreviousBtn, sethidePreviousBtn] = useState(false);
    const [nextLabel, setnextLabel] = useState("Next");
    const [prevLabel, setprevLabel] = useState("Previous");

    const [country, setCountry] = useState();
    const [owner, setOwner] = useState();

    const [entity, setEntity] = useState(null);
    const [facility, setFacility] = useState(null);
   
    const schema = Joi.object().keys({
       
        Customer: Joi.string()
            .min(2)
            .required()
            .error(() => ({ message: "Please enter a valid Customer (at least 2 characters)" })),
        Corporate_Guarantor_ABN: Joi.number()
            .min(2)
            .error(() => ({ message: "Please enter a valid Corporate Guarantor ABN (at least 2 characters)" }))
            .optional().allow(null, ''),
        Corporate_Guarantor_Name: Joi.string()
            .min(2)
            .error(() => ({ message: "Please enter a valid Corporate Guarantor Name (at least 2 characters)" })),
        Corporate_Related_Trust_ABN: Joi.number()
            .min(2)
            .error(() => ({ message: "Please enter a valid Corporate Related Trust ABN (at least 2 characters)" }))
            .optional().allow(null, ''),
        Corporate_Related_Trust_Name: Joi.string()
            .min(2)
            .error(() => ({ message: "Please enter a valid Corporate Related Trust Name (at least 2 characters)" }))
            .optional().allow(null, ''),
        Personal_Guarantor_Name: Joi.string()
            .min(2)
            .error(() => ({ message: "Please enter a valid Personal Guarantor Name (at least 2 characters)" })),
        Related_Trust_ABN: Joi.number()
            .min(2)
            .error(() => ({ message: "Please enter a valid Personal Related Trust ABN (at least 2 characters)" }))
            .optional().allow(null, ''),
        Related_Trust_Name: Joi.string()
            .min(2)
            .error(() => ({ message: "Please enter a valid Personal Related Trust Name (at least 2 characters)" }))
            .optional().allow(null, ''),
        Other: Joi.string()
            .min(2)
            .error(() => ({ message: "Description must be less than or equal to 256 characters" }))
            .optional().allow(null, ''),
            
    }).unknown(true);

    const validate = (excelData) => {
        const options = { abortEarly: false };

        excelData.map((item) => {
            const { error } = Joi.validate(item, schema, options);
            item.error = error ? error.details.map(detail => detail.message).join(", ") : null;
            return item;
        });
    };

    const handleUploadItems = async (excelData, filesList = null) => {
        const spreadSheetFileTypes = ["xls", "xlsx", "ods"];

        // Check for invalid file types
        const invalidFiles = filesList?.filter(
            (fileItem) => !spreadSheetFileTypes.includes(fileItem.name.split(".").pop())
        );

        if (invalidFiles && invalidFiles.length > 0) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="custom-delete-ui">
                            <h4>Invalid File Format!</h4>
                            <p>Please make sure you have uploaded the correct template before proceeding!</p>
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                        </div>
                    );
                },
                title: "",
                message: "",
            });
            return;
        }

        const fieldsToValidate = ['Customer', 'Facility', 'Corporate_Guarantor_Name', 'Personal_Guarantor_Name'];
        const validateRes = ValidateSheetFields(excelData, fieldsToValidate);

        if (validateRes) {
            // Fetch entity_guid for each Customer
            const accountsWithEntityGuid = await Promise.all(
                excelData.map(async (account) => {
                    let entityGuid = null;
                    if (account.Customer) {
                        try {
                            const response = await securityServices.getEntityIdByOrganisation({organisation:account.Customer});
                            entityGuid = response.data?.a_guid || null;
                        } catch (error) {
                            console.error(`Error fetching entity details for Customer ${account.Customer}:`, error);
                        }
                    }

                    // Clean ABN fields to remove whitespace if they exist
                    if (account.Corporate_Guarantor_ABN) {
                        account.Corporate_Guarantor_ABN = account.Corporate_Guarantor_ABN.toString().replace(/\s+/g, '');
                    }
                    if (account.Corporate_Related_Trust_ABN) {
                        account.Corporate_Related_Trust_ABN = account.Corporate_Related_Trust_ABN.toString().replace(/\s+/g, '');
                    }
                    if (account.Related_Trust_ABN) {
                        account.Related_Trust_ABN = account.Related_Trust_ABN.toString().replace(/\s+/g, '');
                    }
    
                    return {
                        ...account,
                        entity_guid: entityGuid,
                    };
                })
            );
    
            filterGuarantors(accountsWithEntityGuid);
            setGuarantors(accountsWithEntityGuid);
    
            if (filesList) {
                let list = [...uploadedFiles];
                filesList.forEach((fileItem) => list.push(fileItem));
                setUploadedFiles(list);
                handleFileReader(list);
            }
        }
    };
    

    const handleFileReader = (inputFiles) => {
        let files = inputFiles.map(fileItem => fileItem.obj);
        let fileslist = [];
        files.forEach(fileItem => {
            let reader = new FileReader();
            reader.readAsDataURL(fileItem);
            reader.onload = e => {
                fileslist.push({
                    name: fileItem.name,
                    file: e.target.result,
                    type: fileItem.type,
                    size: fileItem.size
                });
            };
        });
        setUploadedFileData(fileslist);
        return fileslist;
    };

    const filterGuarantors = (rows) => {
        let accountList = [];
        let duplicateAccounts = [];

        rows.forEach((accountItem, index) => {
            accountItem.index = index;
            accountItem.error = accountItem.error !== undefined ? accountItem.error : null;

            if (accountItem.Account_Number) {
                if (accountList.findIndex(obj => obj.Account_Number === accountItem.Account_Number) === -1) {
                    accountList.push({ ...accountItem });
                } else {
                    duplicateAccounts.push({ ...accountItem });
                }
            } else {
                accountList.push(accountItem);
            }
        });

        setDuplicateAccountsInSheet(duplicateAccounts);
        setUniqueGuarantors(accountList);
        validate(rows);
    };
    const updateGuarantor = (updatedAccount) => {
        let updatedAccounts;
        
        if (Array.isArray(updatedAccount)) {
            updatedAccounts = guarantors.map(account => {
                const update = updatedAccount.find(ua => ua.index === account.index);
                return update ? { ...account, ...update } : account;
            });
        } else {
            updatedAccounts = guarantors.map(account =>
                account.index === updatedAccount.index ? updatedAccount : account
            );
        }
        setGuarantors(updatedAccounts);
        filterGuarantors(updatedAccounts);
    };
    
    const deleteGuarantor = (index) => {
        let accountsList = guarantors.filter(obj => obj.index !== index);
        setGuarantors(accountsList);
        filterGuarantors(accountsList);
    };

    const removeUploadedSheet = (fileName) => {
        setUploadedFiles([]);
        setGuarantors([]);
        setUniqueGuarantors([]);
        setDuplicateAccountsInSheet(0);
        setProceedNext(false); // Ensure next button is disabled
    };

    const value = {
        guarantors,
        handleUploadItems,
        updateGuarantor,
        deleteGuarantor,
        uploadedFiles,
        proceedNext,
        setProceedNext,
        hideNextBtn,
        sethideNextBtn,
        hidePreviousBtn,
        sethidePreviousBtn,
        nextLabel,
        setnextLabel,
        prevLabel,
        setprevLabel,
        uniqueGuarantors,
        setUniqueGuarantors,
        duplicateAccountsInSheet,
        uploading,
        setUploading,
        country,
        setCountry,
        owner,
        setOwner,
        removeUploadedSheet,
        uploadedFileData,
        setUploadedFileData,
        entity,
        setEntity,
        facility,
        setFacility
    };

    return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>;
}
