import React, { useState, useEffect, useImperativeHandle } from "react";
import { NavigationButtons } from "./NavigationButtons";
import { BoxContainer } from "../../common/containers/formContainer";
import ProgressBar from "./ProgressBar";
import { useUploader as suplUseUploader } from "../../../context/supplierUploaderContext";
import { useUploader as accountsUploader } from "../../../context/secAccountsUploaderContext";
import { useUploader } from "../../../context/prospectUploaderContext";
import { useUploader as prospectMigrationUploader } from "../../../context/prospectMigrationContext";
import { useUploader as supplierAccountUploader} from "../../../context/supplierAccountUploaderContext";
import { useUploader as guarantorUploader} from "../../../context/guarantorUploaderContext";

import { useHistory, useLocation } from "react-router-dom";
const MultiStepForm = React.forwardRef((
  {
    list,
    displayProgressBar,
    boxTitle = "",
    type = "",
    proceedNext = true,
    hideNextBtn = false,
    hidePreviousBtn = false,
    nextLabel = "Next",
    prevLabel = "Previous",
    navigate = null,
    handleCancel = null,
    cutsomIndex = null,
    handleNext = null,
    showCard = true,
    epProgram = null,
    data = null,
    handleChange = null,
    handleNextChange = null,
    sProgram = false,
    programId = null,
    ...restProps
    
  }
  , ref) => {

  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isEpProgrammeRoute = location.pathname === "/client/ep-programme";
  const isEpProgrammeSecRoute = location.pathname === "/client/ep-programme/add-new";
  const [step, setStep] = useState(() => {
    const storedStep = localStorage.getItem("step");
    return storedStep ? JSON.parse(storedStep) : 0;
  });
  const history = useHistory();
  const { search } = useLocation();

  const resetQueryStep =() => {
    setStep(0); 
    localStorage.setItem('step', '0');
  }

  useEffect(() => {
    if (isEpProgrammeRoute || isEpProgrammeSecRoute) {
      resetQueryStep();
    }
  }, [isEpProgrammeRoute, isEpProgrammeSecRoute]);
  

  useEffect(() => {
    localStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  useImperativeHandle(ref, () => ({
    next: goNext,
    previous: goPrevious
  }));

  let contextObj = useUploader(); // Instance for Context API

  if (type == 'supplierUploader')
    contextObj = suplUseUploader();

  if (type == 'accountsUploader')
    contextObj = accountsUploader();

  if (type == 'prospectMigration')
    contextObj = prospectMigrationUploader();

  if (type == 'supplierAccountUploader')
    contextObj = supplierAccountUploader();

  if (type == 'guarantorUploader')
    contextObj = guarantorUploader();

  if (type == 'prospectUploader' || type == 'supplierUploader' || type == 'accountsUploader' || type == 'supplierAccountUploader' || type == 'guarantorUploader') {
    proceedNext = contextObj.proceedNext;
    hideNextBtn = contextObj.hideNextBtn;
    hidePreviousBtn = contextObj.hidePreviousBtn;
    nextLabel = contextObj.nextLabel;
    prevLabel = contextObj.prevLabel;
  }

  const goNext = (validate = true) => {

    if(!validate)
    {
      const newStep = step + 1;
      setSelectedIndex(selectedIndex + 1);
      localStorage.setItem('step', JSON.stringify(newStep));
      setStep(newStep);
    }
    else{
      if(sProgram === true && epProgram !== 'view')
      {
        handleNextChange();
      }else{
        var btnSubmit = document.getElementById("btn-submit");

        if (btnSubmit)
          document.getElementById("btn-submit").click();
        else if (selectedIndex != list.length - 1){
          setSelectedIndex(selectedIndex + 1);
          localStorage.setItem('step', JSON.stringify(step + 1));
          setStep(parseInt(step) + 1);
        }
      }
      
    }
  };

  const goPrevious = () => {
    if (selectedIndex != 0 || step != 0) {
      const newStep = step - 1;
      setSelectedIndex(selectedIndex - 1);
      localStorage.setItem('step', JSON.stringify(newStep));
      setStep(newStep);
    }
  };

  const handleSelectIndex = selectedIndex => {
    setSelectedIndex(selectedIndex);
    localStorage.setItem('step', JSON.stringify(selectedIndex));
    setStep(selectedIndex);
  }

  useEffect(() => {
    if (navigate == 'Next') {
      if (selectedIndex != list.length - 1)
        setSelectedIndex(selectedIndex + 1);
        localStorage.setItem('step', JSON.stringify(parseInt(step) + 1));
        setStep(parseInt(step) + 1);
    }
    else if (navigate == 'Previous')
      goPrevious();

  }, [navigate]);

  useEffect(() => {

    if(restProps.displaySteps){
      let pathname = restProps.pathname.includes("step") ? restProps.pathname.substr(0, restProps.pathname.lastIndexOf("/")) : restProps.pathname;
      if(epProgram === 'edit' ||  epProgram === 'view')
      {
        history.push(`${pathname}/step${step + 1}?p_guid=${programId}&step=${step + 1}`);
      }else{
        history.push(`${pathname}/step${selectedIndex+1}`);
      }
      
    }

    // if(restProps.queryStepCount){
    //   const params = new URLSearchParams(search);
    //   params.set('step', selectedIndex+1);
    //   params.set('upload', 'bulk');
    
    //   // Preserve existing query parameters
    //   const newSearch = '?' + params.toString();
    //   const currentUrl = restProps.pathname + search;
    //   const newUrl = currentUrl.replace(/\?.*/, newSearch);
    
    //   history.replace(newUrl);
    // }

  }, [selectedIndex, step]);

  return (
    <>
      <BoxContainer
        boxTitle={boxTitle ? boxTitle : ""}
        handleCancel={handleCancel}
        handleSet={resetQueryStep}
        showCard={showCard}
        epProgram={epProgram}
        data={data}
        handleChange={handleChange}
      >
        {displayProgressBar && (
          <ProgressBar
            list={list}
            selectedIndex={(epProgram === "edit" || epProgram === "view") ? step : selectedIndex}
            handleSelectIndex={handleSelectIndex}
          />
        )}

        {/*-------------------------------*/}
        <div class="hr-line-dashed "></div>
        <div className="row h-200 ">
          {epProgram === "edit" || epProgram === "view" ? (
            <div className="col-lg-12">{list[step].component}</div>
          ) : (
            <div className="col-lg-12">{list[selectedIndex].component}</div>
          )}
        </div>
        {/*----------------------------------------------------------*/}
        {/** ---------------- Navigation buttons ---------------- */}
        <div className="row mt-3">
          <div className="col-lg-12 text-right">
            {selectedIndex == cutsomIndex && type == "" && (
              <button
                style={{ float: "left" }}
                onClick={goNext}
                className="btn btn-outline"
              >
                Skip
              </button>
            )}
            {handleCancel !== null && selectedIndex != list.length - 1 && (
              <button
                onClick={() => {
                  resetQueryStep();
                  handleCancel();
                }}
                class="btn btn-light mr-2"
              >
                Cancel
              </button>
            )}

            <NavigationButtons
              goNext={goNext}
              goPrevious={goPrevious}
              selectedIndex={(epProgram === "edit" || epProgram === "view") ? step : selectedIndex}
              list={list}
              proceedNext={proceedNext}
              hideNextBtn={hideNextBtn}
              hidePreviousBtn={hidePreviousBtn}
              nextLabel={nextLabel}
              prevLabel={prevLabel}
              handleNext={handleNext ? handleNext : null}
            />
          </div>
        </div>
      </BoxContainer>
    </>
  );
});

export default MultiStepForm;
