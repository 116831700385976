import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const Index = () => {
  const [ppsrModal, setPpsrModal] = useState(false);
  return (
    <>
      <h6>Security</h6>
      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <div className="avatar avatar-lg bg-primary  mx-auto mb-3 mb-sm-0 rounded-full text-white">
                  CA
                </div>
                <h5 className="mt-2">CDC Building Group </h5>
                <p>
                  <span className="badge badge-secondary  p-1 ">
                    ABN: 075 692 001
                  </span>
                </p>
                <i class="fa fa-envelope"></i> info@cdcbuilding.com.au
                <i class="fa fa-phone ml-2"></i> +61 502459630
                <p className="mt-4 bg-light p-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="p-4">
                <ul className="stat-list">
                  <li>
                    <h6 className="no-margins">
                      <span>$1,000,000.00</span>
                      <small className="pull-right">SCF facility</small>
                    </h6>

                    <div className="stat-percent"></div>
                    <div className="  ">
                      <div
                        className="progress-bar progress-mini bg-success"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="p-4">
                <ul className="stat-list">
                  <li>
                    <h6 className="no-margins">
                      <span>$500,000.00</span>
                      <small className="pull-right mb-1">TF facility</small>
                    </h6>

                    <div className="stat-percent"></div>
                    <div className="  ">
                      <div
                        className="progress-bar progress-mini bg-danger"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div style={{ height: 400 }}></div>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-11">
                  <ul class="nav nav-tabs mb-4">
                    <li class="nav-item">
                      <a
                        class="nav-link active "
                        href="/dashboard/funding/awaiting-approval"
                      >
                        SCF
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link  "
                        href="/dashboard/funding/awaiting-approval"
                      >
                        TF
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-1">
                  <button className="btn btn-light pull-right">
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        Guarantors
                        <button className="btn btn-sm btn-outline-secondary pull-right">
                          +
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <th>Name</th>
                          <th>ABN</th>
                          <th></th>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-primary">
                              ABC Private Limited{" "}
                              <span className="badge badge-secondary ml-2">
                                Corporate
                              </span>
                            </td>
                            <td>5632008</td>
                            <td>
                              <i className="fa fa-edit pull-right"></i>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-primary">
                              Steven Smith{" "}
                              <span className="badge badge-warning ml-2">
                                Individual
                              </span>
                            </td>
                            <td className="text-muted">N/A</td>
                            <td>
                              <i className="fa fa-edit pull-right"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        PPSR Registration
                        <button className="btn btn-sm btn-outline-secondary pull-right">
                          +
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <th>Entity</th>
                          <th>Ranking</th>
                          <th>Reg Date</th>
                          <th> </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-primary">
                              <a
                                onClick={() => {
                                  setPpsrModal(true);
                                }}
                                href="#"
                              >
                                1 Limited
                              </a>
                            </td>
                            <td>5632008</td>
                            <td>15/07/2023</td>
                            <td>
                              <i className="fa fa-edit pull-right"></i>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-primary">
                              ABC Private Limited
                            </td>
                            <td>5632008</td>
                            <td>15/07/2023</td>
                            <td>
                              <i className="fa fa-edit pull-right"></i>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-primary">
                              Glenndon Pty Ltd IIOC & ATF Probert Investment
                              Trust
                            </td>
                            <td>5632008</td>
                            <td>15/07/2023</td>
                            <td>
                              <i className="fa fa-edit pull-right"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        Trade Credit Insurance
                        <button className="btn btn-sm btn-outline-secondary pull-right">
                          +
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Trade Credit</td>
                            <td className="font-weight-bold">$150,000.00</td>
                            <td className="font-weight-italic">12/05/2023</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        Surety Bond Details
                        <button className="btn btn-sm btn-outline-secondary pull-right">
                          +
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>ABC Private Limited</td>
                            <td>5632008</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-body">
                      <span className="font-weight-bold">Documents</span>
                      <ul className="list-group clear-list m-t">
                        <li className="list-group-item fist-item">
                          <span className="pull-right">
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-cloud-download ml-2"></i>
                          </span>
                          <i className=" fa fa-file-pdf-o mr-2 text-danger"></i>
                          Ppsr Registration Oct 06.pdf{" "}
                          <span className="badge badge-danger ml-2">PPSR</span>
                        </li>
                        <li className="list-group-item fist-item">
                          <span className="pull-right">
                            {" "}
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-cloud-download ml-2"></i>
                          </span>
                          <i className=" fa fa-file-word-o mr-2 text-info"></i>
                          Credit Insurance Policy.doc{" "}
                          <span className="badge badge-warning ml-2">
                            Credit Insurance
                          </span>
                        </li>
                        <li className="list-group-item fist-item">
                          <span className="pull-right">
                            {" "}
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-cloud-download ml-2"></i>
                          </span>
                          <i className=" fa fa-file-excel-o mr-2 text-primary"></i>
                          Trade credit Limit Book.xlsx
                        </li>
                        <li className="list-group-item fist-item">
                          <span className="pull-right">
                            {" "}
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-cloud-download ml-2"></i>
                          </span>
                          <i className=" fa fa-file-pdf-o mr-2 text-danger"></i>
                          Ppsr Registration Oct 06.pdf
                        </li>
                      </ul>
                      <div className="col-lg-12 b-dashed-2 bg-light rounded-lg p-5 mt-4">
                        <div />
                        <div className="d-block text-center">
                          <label
                            htmlFor="file"
                            className="btn btn-secondary bulk_upload_btn"
                          >
                            <i className="fa fa-cloud-upload" />
                            &nbsp;Upload Files
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        Other Security / Risk Mitigants
                        <button className="btn btn-sm btn-outline-secondary pull-right">
                          +
                        </button>
                      </h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-hover margin bottom">
                        <tbody>
                          <tr>
                            <td>Mortgage Ranking</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                55
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Mortgage Registered Date</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                22/05/2023
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Mortgage Registration Removed Date</td>

                            <td className="text-center">
                              <span className="text-warning pull-right">
                                22/02/2023
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Other Relevant Information</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $344.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Caveat Ranking</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $235.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Caveat Registered Date</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $100.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Caveat Registration Removed Date</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $100.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Quensland Caveat</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $100.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Caveat Registered Date</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $100.00
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Estimated Value</td>

                            <td className="text-center">
                              <span className="font-weight-bold pull-right">
                                $100.00
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*------------------Start Supplier Modal -------------------*/}
      <Modal show={ppsrModal} onHide={() => setPpsrModal(false)} size="lg">
        <div className="card-body">
          <h6>
            PPSR Registration{" "}
            <button className="btn btn-sm btn-outline-secondary pull-right">
              Print
            </button>
          </h6>
          <div className="p-4">
            <div className="form-group">
              <h6>Glenndon Pty Ltd IIOC & ATF Probert Investment Trust</h6>
              <label className="control-label">
                Customer GSA Registration Entity
              </label>
            </div>
            <hr />
            <div className="form-group">
              <h6>15/05/2023</h6>
              <label className="control-label">
                Customer GSA Registration Date
              </label>
            </div>
            <hr />
            <div className="form-group">
              <h6>13</h6>
              <label className="control-label">Customer GSA Ranking</label>
            </div>
            <hr />
            <div className="form-group">
              <h6>21/11/2024</h6>
              <label className="control-label">Customer GSA Removed Date</label>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Index;
